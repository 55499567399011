import React from "react";
import { Link } from "gatsby";
import { SEO } from "components";

/* 404 Page
============================================================================= */

const NotFoundPage = () => {
  // Config
  return (
    <>
      <SEO title="Not Found" />
      <div className="w-full h-full bg-purple"></div>
    </>
  );

  // Render
};

/* Export
============================================================================= */

export default NotFoundPage;
